<template>
    <form id="form1" runat="server"  @submit.prevent="handleLogin">
        <div>
            <table width="100%" border="0" align="center" cellpadding="6" cellspacing="0" class="contactform1">
                <tr>
                    <td height="30" colspan="3" align="CENTER" valign="middle" bgcolor="#cccccc" class="textbold"
                        style="padding-left: 5px;">
                        <strong>Admin Login</strong></td>
                </tr>
                <tr>
                    <td height="20" colspan="3" align="center" valign="middle">
                    </td>
    
                </tr>
                <tr>
                    <td width="35%" align="right" valign="middle" class="textgrey">UserName:</td>
                    <td width="29%" align="left" valign="middle">
                        <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required" data-v-8cd163c6 data-v-1dec9371>
    
                            <input type="text" v-model="loginModel.username" name="username" ID="txtEmailId" runat="server" MaxLength="100" class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6
                                TabIndex="1" Width="270"/>
                        </div>
                    </td>
                    <td width="35%">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td width="35%" align="right" valign="middle" class="textgrey">Password:</td>
                    <td width="29%" align="left" valign="middle">
                        <div class="text-input bg-bryton-grey-500 px-4 py-3 text-sm border border-gray-600 tablet:border-gray-300 hover:border-gray-600 text-input--required" data-v-8cd163c6 data-v-1dec9371>
    
                            <input type="password" v-model="loginModel.password" name="password" ID="txtPwd" runat="server" MaxLength="50" TextMode="Password" class="text-input__field placeholder-gray-500 outline-none bg-transparent w-full" data-v-8cd163c6
                                TabIndex="1" Width="270"/>
                        </div>
                    </td>
                     <td width="35%">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td width="35%" align="right" valign="middle" class="textgrey"></td>
                    <td width="29%" align="left" valign="middle">
    
                        <button  type="submit" id="btnContact" runat="server" onserverclick="btnContact_Click" class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black ml-auto font-extrabold text-base leading-none mt-4 tablet:mt-0" style="--text-color: #000; --reactive-text-color: #000" data-v-8e38860e data-v-600a85f8>
                            <div class="animated-underline animated-underline--right" style="--underline-color: var(--bryton-yellow-500); --default-underline-width: 33%; --tablet-underline-width: 33%; --desktop-underline-width: 33%; --default-underline-alignment: right; --tablet-underline-alignment: right; --desktop-underline-alignment: right; --default-alignment: flex-end; --tablet-alignment: flex-end; --desktop-alignment: flex-end" data-v-52d451c5 data-v-8e38860e>
                                Login
                    
                            </div>
                        </button>
                    </td>
                     <td width="35%">
                        &nbsp;
                    </td>
                </tr>
                <tr>
                    <td width="31%" align="right" valign="middle" class="textgrey"></td>
                    <td width="69%" align="left" colspan="2" valign="middle" style="color: red;">
    
    
                        <div class="text-bryton-red-500" id="divMsg" runat="server" style="font-weight: bold;" data-v-600a85f8>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="4" align="center" valign="middle">&nbsp;</td>
                </tr>
            </table>
        </div>
    </form>
</template>
<script>
import AdminService from '@/Services/AdminService';

export default {
    data() {
        return {
            loginModel:{
            username: '',
            password: '',
            },
            errorMessage: ''
            
        };
    },
    methods: {
    //     async handleLogin() {
    //         // eslint-disable-next-line
    //          debugger;
    //         try {
    //             const response = await axios.post('https://node.brytonprinting.com/api/Login/loginRequest',this.loginModel, {
    //                 headers: {
    //       'Content-Type': 'application/json'
    //                 },
    //         })
        

    //             if (response.data.statusCode==200) {
    //               // eslint-disable-next-line
    //                 debugger
    //                 localStorage.setItem("userType", this.loginModel.username);
    //                 this.$router.push('/UploadFileListing');
    //             } else {
    //                 this.errorMessage = 'Invalid username or password.';
    //             }
    //         } catch (error) {
    //             this.errorMessage = 'An error occurred during login. Please try again.';
    //         }
    //     }
    // }
    async handleLogin() {
    
        try {

            AdminService.handleLogin(this.loginModel).then((response) => {
        console.log('API Response:', response);
          this.usersData = response.data;
          if (response.data.statusCode==200) {
                  // eslint-disable-next-line
                    debugger
                    localStorage.setItem("userType", this.loginModel.username);
                    this.$router.push('/UploadFileListing');
                } else if(response.data.statusCode==401){
                    this.errorMessage = 'Invalid username or password.';
                }
          //this.documentsDetail = response.data[0].DocumentsDetail;
          console.log('Users Data:', this.usersData);    
          });
    }
    catch (error) {
          console.error("There was an error fetching user data:", error);
        }
      }
      
}
}

</script>
<style>
.contactform1 .text-input {
    width: 100% !important;
    margin-bottom: 0 !important;
  }
</style>