<template>
   

    <main class="flex-container flex-container--two-col page-container page-container--padded pb-8 desktop:pb-16 flex-grow" data-v-1208f910="">
                  
                   

        <!-- Breadcrumbs-->
             <!-- <section class="breadcrumbs-custom bg-image context-dark">
                <div class="container">
                   <ul class="breadcrumbs-custom-path">
                      <li class="active">Thank You</li>
                   </ul>
                   <h3 class="breadcrumbs-custom-title">Thank You</h3>
                </div>
             </section> -->         
             <!--Mailform-->
             <section class="section section-lg bg-default">
                <div class="container">
                   <div class="row justify-content-md-center">
                       <div class="col-md-9 col-lg-8 col-xl-7 text-center">
                          <div style="text-align:center;"><img src="../assets/images/Thank_you_image.png" style="width:250px; display:inline; max-width:100%;"></div>
                            <h4>Your file has been uploaded successfully. We will review it and get back to you shortly.</h4>
                          
                       </div>
                    </div>
                </div>
             </section>
    
    
                   
                   </main>

</template>

<style>
.flex-container{
    min-height: 50vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
</style>