
import axios from "axios";

const baseCommonURL = 'https://node.brytonprinting.com/api';  

const api = axios.create({
    baseURL: baseCommonURL
});

class AdminService {
    
    async GetEmailValidation() {
        // eslint-disable-next-line
     //   debugger;
         return await api.get(`ContactForm/GetEmailValidation`);
     }
     fetchUserData(formData) {
        // eslint-disable-next-line
        debugger;
        return api.post('ContactForm/GetContact', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      }
      uploadForm(model) {
        return axios.post('ContactForm/PostContactForm', model)
      }
      handleLogin(loginModel) {
        // eslint-disable-next-line
        debugger;
        return api.post('Login/loginRequest', loginModel)
      }
      handleSubmit(formData) {
        // eslint-disable-next-line
        debugger;
        
        return api.post('ContactForm/SendContact', formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
        }
          })
      }
}

export default new AdminService();
