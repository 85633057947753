import { createRouter, createWebHistory } from 'vue-router'
import Contact from '../views/ContactView.vue'
import PrintingServices from '../views/PrintingServices.vue'
import EnviormentView from '@/views/EnviormentView.vue'
// import UploadFile from '@/views/UploadFile.vue'
import UploadFileThankYou from '@/views/UploadFileThankYou.vue'
import ThankYou from '@/views/ThankYou.vue'
import FileList from '@/views/FileListing.vue' 
import Login from '@/views/LogIn.vue'
import Index from '@/views/IndexView.vue'
import DummySider from '../components/DummySider.vue'
const routes = [
  
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/PrintingServices',
    name: 'PrintingServices',
    component: PrintingServices
  },
  
  {
    path: '/Enviorment',
    name: 'Enviorment',
    component: EnviormentView
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  
  {
    path: '/Contact', 
    name: 'Contact',
    component: Contact
  },
  // {
  //   path: '/UploadFile', 
  //   name: 'UploadFile',
  //   component: UploadFile
  // },
  {
    path: '/Upload/ThankYou',
    name: 'UploadFileThankYou',
    component: UploadFileThankYou
  },
  {
    path: '/Contact/Thankyou',
    name: 'Thankyou',
    component: ThankYou
  },
  {
    path: '/UploadFileListing',
    name: 'FileList',
    component: FileList
  },
  {
    path: '/DummySider',
    name: 'DummySider',
    component: DummySider
  },

  {
    path: '/UploadFiles',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
