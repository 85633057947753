<template>
  <table width="100%" border="0" align="center" cellpadding="6" cellspacing="0">
    <tbody>
      <tr>
        <td height="30" colspan="3" align="CENTER" valign="middle" bgcolor="#cccccc" class="textbold"
          style="padding-left: 5px;">
          <strong style="text-align: center;margin: auto;display: flex;justify-content: center;align-items: center;">List of Submissions</strong>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="container">
    <div class="col-lg-12">

      <div class="row search-box-row">

        <div class="col-md-12">

          <div class="col-serach-box">
            <div class="enable-email col-span-full" data-v-7b32b693>
              <input type="checkbox" v-model="isEmailValidate" />Enable Email
            </div>
            <div class="logout-main-boxes">
              <Button @click="EnableEmailValidation()"
                class="btn-logout  nav__item bolding-link btn btn-success btn-primary text-center">Submit</Button>
            </div>
          </div>

          <div class="logout-main-boxes">
            <Button @click="logOut"
              class="btn-logout nav__item bolding-link btn btn-primary text-center">Logout</Button>
          </div>


        </div>
      </div>
    </div>
    <div class="col-md-12 col-box-div">
      <input class="form-control mt-0" type="text" v-model='searchvalue' placeholder="Search" @keyup.enter="searchs()">
      <button type="submit" class="btn btn-primary color-4" @click="searchs()" title="Search">
        Search
      </button>
      <button type="submit" class="btn ms-2 px-4 btn-danger" @click="reset()" title="reset"><i
          class="fa fa-refresh"></i></button>
    </div>
    <div class="table-main-div">
      <table class="table-background" style="width:1000px;margin:auto">
        <tr>
          <th>Name</th>
          <th>Company</th>
          <!-- <th>PostDate</th> -->
          <th>Email</th>
          <th>Description</th>
          <th>Files</th>
          <th>Action</th>
        </tr>
        <tr v-for="user in usersData" :key="user.ContactId">
          <td>{{ user.Name }}</td>
          <td>{{ user.CompanyName }}</td>
          <td>{{ user.Email }}</td>
          <td>{{ user.Message }}</td>
          <td>

            <span v-for="fileItem in JSON.parse(user.DocumentsDetail)" :key="fileItem.FileId">
              <h1>
                <a :href="`https://node.brytonprinting.com/upload/${fileItem.FolderName}/${fileItem.FileName}`" target="_blank">
                  {{ fileItem.Display_Name }}
                </a>
               
              </h1>
            </span>
          </td>
          <td><button class="btn btn-danger"
              @click="openModal(user.ContactId, JSON.parse(user.DocumentsDetail)[0].FolderName)">Delete</button></td>
        </tr>


      </table>
    </div>

    <div class="pagination">
      <button @click="prevPage" :disabled="pageindex === 1">Previous</button>
      <span>Page {{ this.pageindex }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="pageindex === totalPages">Next</button>
    </div>



    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="close-button" @click="closeModal">X</button>
        <h2 class="modal-heading">Confirm Deletion</h2>
        <p class="modal-text">Are you sure you want to delete this document detail? If you delete this document, it will
          be permanently deleted and you will not be able to recover it?</p>
        <div class="modal-btn">
          <button class="btn btn-primary" @click="confirmDelete">Yes, Delete</button>
          <button class="btn btn-danger" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="visible" class="modal-overlay">
    <div class="modal">
      <p>Data Deleted successfully!</p>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import AdminService from '@/Services/AdminService';


export default {
  data() {
    return {
      isEmailValidate: false,
      showModal: false,
      pageindex: 1,
      pagesize: 10,
      orderBy: 'Desc',
      sortBy: 'CreatedOn',
      searchTxt: '',
      searchvalue: '',
      usersData: [],
      currentPage: 1,
      pageSize: 10,
      documentsDetail: [],
      totalRecord: 1,
      totalPages: 1,
      visible: false,
    };
  },
  async created() {
    await this.fetchUserData();
    await this.GetEmailValidation();
  },
  methods: {
    async fetchUserData() {
      if (localStorage.getItem('userType') == null) {
        this.$router.push('/');
      } else {
        try {

          const dataModel = {
            pageindex: this.pageindex,
            pagesize: this.pagesize,
            sortBy: this.sortBy,
            orderBy: this.orderBy,
            searchTxt: this.searchTxt,
          };
      AdminService.fetchUserData(dataModel).then((response) => {
        // eslint-disable-next-line
        debugger;
        console.log('API Response:', response);
          this.usersData = response.data;
          if (this.usersData.length > 0) {
            this.totalRecord = this.usersData[0].TotalRecord;
            this.totalPages = Math.ceil(this.totalRecord / this.pageSize);
          }
           this.documentsDetail = response.data[0].DocumentsDetail;
          console.log('Users Data:', this.usersData); 
             });
    }
    catch (error) {
          console.error("There was an error fetching user data:", error);
        }
      }
      },

    // async fetchUserData() {
    //   // eslint-disable-next-line
    //   debugger;

    //   if (localStorage.getItem('userType') == null) {
    //     this.$router.push('/');
    //   } else {
    //     try {

    //       const dataModel = {
    //         pageindex: this.pageindex,
    //         pagesize: this.pagesize,
    //         sortBy: this.sortBy,
    //         orderBy: this.orderBy,
    //         searchTxt: this.searchTxt,
    //       };

    //       const response = await axios.post('https://node.brytonprinting.com/api/ContactForm/GetContact', dataModel, {
    //         headers: {
    //           'Content-Type': 'multipart/form-data'
    //         },
    //       })

    //       console.log('API Response:', response);
    //       this.usersData = response.data;
    //       if (this.usersData.length > 0) {
    //         this.totalRecord = this.usersData[0].TotalRecord;
    //         this.totalPages = Math.ceil(this.totalRecord / this.pageSize);
    //       }
    //       this.documentsDetail = response.data[0].DocumentsDetail;
    //       console.log('Users Data:', this.usersData);
    //     } catch (error) {
    //       console.error("There was an error fetching user data:", error);
    //     }
    //   }
    // },
    show() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 3000); // Hide after 3 seconds
    },

    logOut() {
      localStorage.removeItem('userType');
      this.$router.push('/');
    },
    prevPage() {
      if (this.pageSize > 1) {
        this.pageindex--;
        this.fetchUserData();
      }

    },
    nextPage() {
      this.pageindex++;
      this.fetchUserData();

    },
    openModal(contactId, folderName) {
      this.contactId = contactId;
      this.folderName = folderName;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    confirmDelete() {
      this.DeleteById(this.contactId, this.folderName);
      this.closeModal();
    },

    DeleteById(ContactId, FolderName) {
      const userType = localStorage.getItem('userType');
      const dataModel = {
        ContactId: ContactId,
        userType: userType,
        FolderName: FolderName,
      };

      axios.post('https://node.brytonprinting.com/api/ContactForm/DeleteDocumentById', dataModel, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          console.log('Document deleted successfully:', response.data);
          this.show();
          this.fetchUserData();
        })
        .catch(error => {
          console.error('Error deleting document:', error);
        });
    },
    openFile(fileUrl, folderName) {
      const filePath = `https://node.brytonprinting.com/Upload/${folderName}/${fileUrl}`;
      window.open(filePath, '_blank');
    },
    downloadFile(fileUrl, fileName) {
      axios({
        url: fileUrl,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }, 0);
        })
        .catch(error => {
          console.error("There was an error downloading the file:", error);
        });
    },
    sanitizeDisplayName(name) {
      return name.replace(/[_@#]/g, '');
    },
    searchs() {
      this.Loading = true
      this.searchTxt = this.searchvalue
      this.fetchUserData();

    },
    reset() {
      this.searchTxt = '';
      this.searchvalue = '',
        this.pageSize = 5
      this.fetchUserData();
    },
    async EnableEmailValidation() {
      // eslint-disable-next-line
      debugger;

      try {
        const response = await axios.get(`https://node.brytonprinting.com/api/ContactForm/ManageEmailValidation/${this.isEmailValidate}`, {
          headers: {
            'Content-Type': 'application/json'
          },
        });

        // Check HTTP status code correctly
        if (response.status === 200) {
          // eslint-disable-next-line
          debugger;
          localStorage.setItem("userType", this.loginModel.username);
          this.$router.push('/UploadFileListing');
        } else {
          this.errorMessage = 'Invalid username or password.';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again.';
      }
    },

    async GetEmailValidation() {
      // eslint-disable-next-line
      debugger;

      try {
        const response = await axios.get(`https://node.brytonprinting.com/api/ContactForm/GetEmailValidation`, {
          headers: {
            'Content-Type': 'application/json'
          },
        });

        // Check HTTP status code correctly
        if (response.status === 200) {
          // eslint-disable-next-line
          debugger;
          this.isEmailValidate = response.data[0].email;
          localStorage.setItem("userType", this.loginModel.username);
          this.$router.push('/UploadFileListing');
        } else {
          this.errorMessage = 'Invalid username or password.';
        }
      } catch (error) {
        this.errorMessage = 'An error occurred during login. Please try again.';
      }
    }
  }
};
</script>


<style scoped>
input[type="checkbox"] {
  width: 22px;
  /* display: flex; */
  height: 18px;
}


.enable-email.col-span-full {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-md-12.col-box-div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0px;
  padding: 0px;
  width: 1000px !important;
  margin: auto;
  margin-bottom: 13px;
}

.enable-email.col-span-full {
  margin-right: 20px;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f7f9;
  color: #333;
  margin: 0;
  padding: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

h1 {
  text-align: center;
  color: #333;
  padding: 20px;
  background-color: #007bff;
  color: white;
  margin: 0;
  border-bottom: 2px solid #0056b3;
}

/* Styles for user cards */
.user-card {
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px auto;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.user-card h2 {
  margin: 0 0 10px;
  font-size: 1.6em;
  color: #007bff;
}

.user-card p {
  margin: 5px 0;
  font-size: 1.1em;
  color: #555;
}

.user-card h3 {
  margin: 15px 0;
  font-size: 1.3em;
  color: #444;
}

/* Styles for file list */
.user-card ul {
  list-style: none;
  padding: 0;
}

.user-card li {
  margin: 10px 0;
  font-size: 1.1em;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.user-card li:hover {
  background-color: #e9ecef;
}

.user-card li span {
  flex-grow: 1;
}

.user-card li button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.user-card li button:hover {
  background-color: #218838;
}

.user-card li a {
  text-decoration: none;
  color: #007bff;
}

.user-card li a:hover {
  text-decoration: underline;
}

.user-card .no-files {
  margin-top: 10px;
  font-style: italic;
  color: #999;
  text-align: center;
}

/* Pagination styles */
.pagination {
  text-align: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1em;
  margin: 0 10px;
}

.file-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.file-link:hover {
  text-decoration: none;
}

.btn.btn-gradient.color-4::after {
  opacity: 0.3;
  box-shadow: none;
}

.col-serach-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-serach-box {
  margin: 41px 0px;
}

input.form-control.mt-0 {
  width: 330px;
  height: 42px;
  border-radius: 3px;
}

button.btn.btn-primary.color-4 {
  padding: 10px 32px;
  border-radius: 3px;
}

button.btn.ms-2.px-4.btn-danger {
  padding: 10px 20px;
  border-radius: 3px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}



th,
td {
  text-align: left;
  padding: 8px;
}

button.btn-logout.nav__item.bolding-link.btn.btn-primary.text-center {
  color: #fff;
}

table {
  border: 1px solid #dbdbdb;
  border-collapse: collapse;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}





.table-background {
  max-width: 1000px;
  padding: 70px 0px !important;
}

.modal-content {
  width: 569px;
}

.close-button[data-v-a192c5c4] {
  width: 20px;
}

.modal-btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}

h2.modal-heading {
  text-align: center;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

p.modal-text {
  padding-top: 9px;
  text-align: center;
}

table,
th,
td {
  border: 1px solid #e5e5e5;
}

h1[data-v-a192c5c4] {
  text-align: center;
  color: #333;
  padding: 20px;
  color: #857b7b;
  border: 1px solid #b7b7b7;
  margin: 0;
  background-color: transparent;
  border-radius: 2px;
}

.col-md-12 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 120px;
  padding: 0px;
}

.row.search-box-row {
  width: 1000px;
  margin: auto;
}

button.btn-logout.nav__item.bolding-link.btn.btn-primary.text-center[data-v-a192c5c4] {
  color: #fff;
  padding: 10px 32px;
}
</style>