<template>
    <footer class="footer">
      
        <div class="page-container page-container--padded">
            <section class="angled-strip angled-strip--accented angled-strip--bottom" style="--color: var(--bryton-grey-500); --accent-color: var(--bryton-magenta-500); --angle: 9deg; --accent-scale-factor: 0.1326121177843972; --default-scale-factor: 0.9876883405951378; --desktop-scale-factor: 1.8427645634058782; --default-margin-y: 7.919222016226814%; --tablet-margin-y: 7.919222016226814%; --desktop-margin-y: 15.838444032453628%; --default-transform-origin-x: center; --desktop-transform-origin-x: left; --default-top-transform-origin-x: center; --desktop-top-transform-origin-x: right;"
            data-v-7b187159="" data-v-17174542="">
                <div class="angled-strip__accent" data-v-7b187159=""></div>
                <div class="angled-strip__background" data-v-7b187159=""></div>
                <div class="footer__container tablet:text-xs flex flex-col tablet:flex-row tablet:flex-wrap pb-4 tablet:pb-16 pt-24 tablet:pt-32 desktop:pt-8" data-v-7b187159="" data-v-17174542="">
                    <div class="footer__contact grid grid-cols-2 tablet:gap-x-12" data-v-7b187159="" data-v-17174542="">
                        <h2 class="footer__header pb-4 tablet:pb-0 col-span-2" data-v-7b187159="" data-v-17174542="">
          CONTACT
        </h2>
                        <div class="flex flex-col justify-between leading-loose tablet:leading-normal" data-v-7b187159="" data-v-17174542="">
                            <div data-v-7b187159="" data-v-17174542="">Jeff Weston</div>
                            <div data-v-7b187159="" data-v-17174542=""><a href="tel:+1-714-993-4800" class="footer__link" data-v-7b187159="" data-v-17174542="">
              714-993-4800
            </a></div>
                            <div data-v-7b187159="" data-v-17174542=""><a href="mailto:Jeff@BrytonPrinting.com" class="footer__link" data-v-7b187159="" data-v-17174542="">
              Jeff@BrytonPrinting.com
            </a></div>
                        </div>
                        <div class="flex flex-col justify-between" data-v-7b187159="" data-v-17174542="">
                            <div class="leading-tight mb-3" data-v-7b187159="" data-v-17174542="">
                                <div data-v-7b187159="" data-v-17174542="">Bryton Printing</div>
                                <div data-v-7b187159="" data-v-17174542="">3345 E Miraloma Ave Unit 134</div>
                                <div data-v-7b187159="" data-v-17174542="">
                                    Anaheim, CA 92806
                                </div>
                            </div> <a href="https://www.google.com/maps/place/Bryton+Printing/@33.861183,-117.85112,16z/data=!4m5!3m4!1s0x0:0xd6287fcb4956d260!8m2!3d33.8611828!4d-117.8511197?hl=en" target="_blank" rel="noopener" class="footer__link" data-v-7b187159=""
                            data-v-17174542="">
            Map / Directions
          </a></div>
                    </div>
                    <div class="mt-4 desktop:mt-0 justify-self-end self-end flex-grow" data-v-7b187159="" data-v-17174542="">
                        <div class="hidden tablet:block text-right text-gray-500 text-5xl lowercase" data-v-7b187159="" data-v-17174542=""><span class="font-black mr-1" data-v-7b187159="" data-v-17174542="">bryton</span>printing
                        </div>
                        <div class="text-gray-700 text-right mt-3" data-v-7b187159="" data-v-17174542="">
                            ©2012 Bryton Printing. All Rights Reserved.
                        </div>
                    </div>
                </div>
                <div class="flair" style="--default-flair-bottom:0px;--tablet-flair-bottom:0px;--desktop-flair-bottom:0px;--desktop-flair-margin-bottom:7.919222016226814%" data-v-7b187159=""></div>
            </section>
        </div>

    </footer>

</template>
<style>

</style>