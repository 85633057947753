<template>
    <div class="easter-egg__channel hidden desktop:block" style="width: calc(var(--container-padding-x) + 193px - 75px)">
        <div class="easter-egg">
            small details matter to us

        </div>
    </div>

    <main class="tablet:pt-24 page-container page-container--padded pb-8 desktop:pb-16 flex-grow" data-v-7cfdcb1c>
        <!---->
        <div class="w-full text-center text-3xl tablet:text-6xl leading-tight uppercase mb-6 desktop:mb-16" data-v-7cfdcb1c>
            <h1 class="font-black" data-v-7cfdcb1c>
                catalog and booklet printing
            </h1>
            <span class="font-medium" data-v-7cfdcb1c>that will enhance your company image</span>
        </div>
        <section class="w-full flex flex-col desktop:pb-8 items-center desktop:grid desktop:grid-rows-1 desktop:grid-cols-3 mb-24" data-v-7cfdcb1c>
            <div class="intro__img-container col-span-1 row-span-1 relative z-0 w-10/12 max-w-lg mb-10 desktop:mb-0" data-v-7cfdcb1c>
                <picture data-v-7cfdcb1c>

                    <img src="../assets/images/e990e5.png" width="942" height="584" alt="Vividly colored example catalogs" class="absolute intro__img w-full h-auto" style="left:50%;transform:translate(-50%,0)">
                </picture>
            </div>
            <div class="pl-6 col-span-2 row-span-1 self-end text-center desktop:text-left" data-v-7cfdcb1c>
                <h2 class="text-2xl mb-12 uppercase" style="text-align: left;" data-v-7cfdcb1c>
                    Perfect when you need 500 catalogs or more
                </h2>
                <div class="text-lg nuxt-content" data-v-7cfdcb1c>
                    <p style="text-align: left;" data-v-7cfdcb1c>
                        At Bryton Printing, our friendly team of experts is here to listen closely to
                        your goals and provide guidance. We learn what you need and give the best
                        options to make sure your booklet is a success.
                        <a href="tel:+1-714-993-4800" class="hover:text-bryton-magenta-500 focus:to-bryton-magenta-500" data-v-7cfdcb1c>
                            Call now
                        </a>
                        and let us be your partners in printing.
                    </p>
                </div>
            </div>
        </section>
        <section class="text-color-p text-white py-24 angled-strip angled-strip--accented angled-strip--middle" style="--color:var(--bryton-magenta-500);--accent-color:var(--bryton-yellow-500);--angle:9deg;--accent-scale-factor:1;--default-scale-factor:1;--desktop-scale-factor:1;--default-margin-y:7.919222016226814%;--tablet-margin-y:7.919222016226814%;--desktop-margin-y:15.838444032453628%;--default-transform-origin-x:center;--desktop-transform-origin-x:center;--default-top-transform-origin-x:center;--desktop-top-transform-origin-x:center" data-v-7b187159 data-v-7cfdcb1c>
            <div class="angled-strip__accent" data-v-7b187159></div>
            <div class="angled-strip__background" data-v-7b187159></div>
            <div class="benefits" data-v-7b187159 data-v-7cfdcb1c>
                <div class="w-full h-full row-span-1 col-span-2" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>

                        <img src="../assets/images/52dea9.png" width="50" height="50" alt="A stamp of approval" class="mb-4 desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        trustworthy
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>With over 37 years of experience, we are the printer that you can trust.</p>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-2" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>

                        <img src="../assets/images/c7e380.png" width="50" height="50" alt="A mouse pointer clicking" loading="lazy" class="mb-4  desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        Easy Ordering
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            Get an accurate quote upfront in just moments - in many cases, while you are on
                            the phone.
                        </p>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-2" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>

                        <img src="../assets/images/a72408.png" width="50" height="50" alt="A set of gears" loading="lazy" class="mb-4  desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        new equipment
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            Our equipment is new, our shop is clean and, we love to show it off. Customers
                            are welcome to see their order run on our state-of-the-art equipment. You may
                            visit any time.
                        </p>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-3" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>
                        <img src="../assets/images/78a962.png" width="50" height="50" alt="A color picker surrounded by a ring of color swatches" loading="lazy" class="mb-4  desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        superior color
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            Bryton Printing delivers consistent and accurate color that makes your products
                            and services look great. We have:
                        </p>
                        <ul data-v-7b187159 data-v-7cfdcb1c>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                Epson hard copy proofs: You can see the color you get before it is printed on
                                the press with Epson hard copy proofs.
                            </li>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                Closed-loop scanning spectrophotometer: This is a fancy way of saying each
                                sheet is scanned for color and quality.
                            </li>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                UV cured ink: UV cured ink is ink cured (dried) with Ultra Violet light. This
                                type of ink delivers more vivid color while keeping natural color looking
                                natural - making your products unforgettable.
                            </li>
                            <li data-v-7b187159 data-v-7cfdcb1c>We save samples of your printing so, we can match future orders to the first order.</li>
                        </ul>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-3" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>
                        <source media="(max-width: 460px)" sizes="280px" srcset="f36696.webp, 6f979b.webp 2x" type="image/webp">
                        <source media="(max-width: 640px)" sizes="410px" srcset="eb227f.webp, f1658a.webp 2x" type="image/webp">
                        <source media="(max-width: 768px)" sizes="590px" srcset="9c4925.webp, 2d3258.jpg 1.5186440677966102x" type="image/webp">
                        <source media="(max-width: 1024px)" sizes="680px" srcset="9af56b.webp, 2d3258.jpg 1.3176470588235294x" type="image/webp">
                        <source media="(max-width: 460px)" sizes="280px" srcset="2c23c6.png, 599918.png 2x" type="image/png">
                        <source media="(max-width: 640px)" sizes="410px" srcset="e1eee4.png, 7cae05.png 2x" type="image/png">
                        <source media="(max-width: 768px)" sizes="590px" srcset="a29c2f.png, 551237.png 1.5186440677966102x" type="image/png">
                        <source media="(max-width: 1024px)" sizes="680px" srcset="617d23.png, 551237.png 1.3176470588235294x" type="image/png">
                        <img src="../assets/images/551237.png" width="896" height="664" alt="A pristine RMGT 945 large industrial printing machine" loading="lazy" class="w-full h-full object-contain">
                    </picture>
                </div>
                <div class="w-full h-full row-span-1 col-span-6" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>
                        <img src="../assets/images/39f7d8.png" width="50" height="50" alt="Sizes and options that fit your needs" loading="lazy" class="mb-4  desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        sizes and options
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            At Bryton Printing, we offer all of the standard size booklets and paper
                            options. Plus, we offer many custom features. Here is a partial list of options:
                        </p>
                        <ul data-v-7b187159 data-v-7cfdcb1c>
                            <li data-v-7b187159 data-v-7cfdcb1c>Custom Finish Sizes</li>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                Order the exact quantity that you want. You don't need to order in multiples
                                of 500.
                            </li>
                            <li data-v-7b187159 data-v-7cfdcb1c>Wide or tall booklets: Also known as landscape or portrait catalogs.</li>
                            <li data-v-7b187159 data-v-7cfdcb1c>Multiple binding styles: Saddles Stitched; Perfect Bound; Plastic Coil; and, Wire-O</li>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                Paper selection: Choose from Glossy, Matte or Uncoated paper. Our staff can
                                assist with these options.
                            </li>
                            <li data-v-7b187159 data-v-7cfdcb1c>
                                <a href="/index#options" data-v-7cfdcb1c>
                                    Click here to see a full list of catalog options
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-3" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>
                        <img src="../assets/images/eb5fd7.png" width="50" height="50" alt="A customer service headset" loading="lazy" class="mb-4  desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        exceptional service
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            Our dedicated team gives you exceptional service - we update your orders
                            progress each step of the way. You are always welcome to contact us.
                        </p>
                    </div>
                </div>
                <div class="w-full h-full row-span-1 col-span-3" data-v-7b187159 data-v-7cfdcb1c>
                    <picture data-v-7cfdcb1c>
                        <img src="../assets/images/57405e.png" width="50" height="50" alt="A speeding stopwatch" loading="lazy" class="mb-4 desktop:mx-0">
                    </picture>
                    <h3 class="mb-2 uppercase font-black text-xl border-b border-white" style="color:#f7ec22" data-v-7b187159 data-v-7cfdcb1c>
                        fast service
                    </h3>
                    <div class="nuxt-content" data-v-7b187159 data-v-7cfdcb1c>
                        <p data-v-7b187159 data-v-7cfdcb1c>
                            We meet your tight deadlines - without ever compromising quality. This way, you
                            get what you need, exactly when you need it. Weekend printing is available for a
                            slight price increase.
                        </p>
                    </div>
                </div>
            </div>
            <div class="flair" style="--default-flair-bottom:0px;--tablet-flair-bottom:0px;--desktop-flair-bottom:0px;--desktop-flair-margin-bottom:7.919222016226814%" data-v-7b187159></div>
        </section>
        <section class="w-full text-center pt-20 desktop:pt-32" data-v-7cfdcb1c>
            <h2 id="options" class="anchor-target text-4xl desktop:text-6xl leading-tightest font-medium mb-6 desktop:mb-16 uppercase" data-v-7cfdcb1c>
                booklet and catalogs<br data-v-7cfdcb1c> <span class="font-black" data-v-7cfdcb1c>options</span>
            </h2>
            <p class="text-lg desktop:text-2xl font-semibold mb-6 desktop:mb-12 max-w-4xl w-full mx-auto" data-v-7cfdcb1c>
                There are many options when ordering a catalogue or a booklet. Below, is a description of the various options.
            </p>
            <div class="flex flex-col items-center desktop:items-start desktop:grid desktop:grid-cols-7 desktop:gap-x-8 desktop:gap-y-2 mb-24" data-v-7cfdcb1c>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-xl" data-v-7cfdcb1c>
                    size:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Ordering a catalog starts with the size that you need. The standard size for a
                            booklet or catalogue is 8.5x11 inches. However, many different custom catalog
                            job sizes can be ordered: 5.5x8.5; 6x9; 8.5x8.5; 8.5x11; 9x12; 11x17. Smaller
                            sizes might be chosen to reduce mailing costs or, so a booklet will fit into
                            someone's purse. Larger booklets can be more dramatic and can be used to get
                            attention.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            The size of a catalogue is measured when the catalog is closed. The width is the
                            first measurement and the height is the second measurement. We don't need to
                            know the size of the catalogue while it is open.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-xl" data-v-7cfdcb1c>
                    orientation:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Orientation is whether your book is tall or wide. Tall booklets are most popular
                            and are good for catalogs because they are standard. Tall booklets are called
                            portrait. Wide booklets are great for marketing. They are called Album Style or
                            Landscape. We welcome catalog designers with a special vision for a custom
                            order.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-lg" data-v-7cfdcb1c>
                    number of pages:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            For the sake of pricing, the cover of a booklet is counted as page one. This is
                            regardless of the way the booklet is actually numbered. To get the total number
                            of pages, count from the front cover to the back cover. This is the number that
                            you need to give us when you are ordering.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-lg" data-v-7cfdcb1c>
                    Cover Options:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            You have two cover options for a catalog. First, the outside cover can be the
                            same thickness as the inside of a booklet. This is called Self Cover. Self-cover
                            makes your booklet slightly thinner. It also saves money.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            The other cover option is to have the outside cover thicker than the inside
                            pages. When ordering a booklet that has a thicker cover, you would request,100
                            pages plus cover or 120 pages plus cover. A thicker cover makes a catalog more
                            durable. It also adds a bit to the cost.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            If a thicker paper is added for the outside cover, the cover pages are counted
                            separately. So, a for catalog that is 104 pages, when you order, you will
                            request 100 pages plus cover. At Bryton Printing, we will make sure to ask the
                            right questions to make sure we give an accurate price.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-xl" data-v-7cfdcb1c>
                    Quantity:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            The quantity that you need determines what type of printing company you will
                            use. If the quantity that you need is between 1 - 500, a digital press (copier)
                            is best. The quality of digital printing has improved a great deal in the last
                            10 years. Although we don't do digital printing here yet, we are happy to refer
                            you to a good digital printer.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            If the quantity is greater than 500, a lithographic press is best. Lithography
                            is a fancy term for a traditional press that prints ink on paper. This is the
                            type of press that we use at Bryton Printing. This method of printing allows
                            more options and still gives the best quality. One option for a printing job is
                            gloss or dull varnish. Another option for a custom print job is special colors.
                            For example, metallic ink and fluorescent ink.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            If the quantity is very large, more than 20,000 booklets, a web press is best.
                            Web presses run a roll of paper and then cut the paper into sheets for binding.
                            These press can run extremely fast. The speed of the press allows the cost to be
                            lower on large quantities but, the quality can suffer in some cases.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-lg" data-v-7cfdcb1c>
                    Type of Paper:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div data-v-7cfdcb1c>
                        <div class="grid grid-rows-2 grid-cols-1 tablet:grid-rows-1 tablet:grid-cols-2" data-v-7cfdcb1c>
                            <div class="nuxt-content" data-v-7cfdcb1c>
                                <p style="text-align:left" data-v-7cfdcb1c>
                                    There are three basic types of paper stock: Gloss; Satin; and, Uncoated. Gloss
                                    paper shows the most detail. It is also the most common type of paper for a
                                    catalog. Satin paper shows most of the detail of gloss but is less shiny and has
                                    less glare. Finally, uncoated paper is good when you need forms filled out. It
                                    also has advantages when printing pictures of faces, clothing and nature.
                                    Uncoated paper is where higher quality paper really improves the quality of the
                                    printing.
                                </p>
                            </div>
                            <div class="relative w-full h-full" data-v-7cfdcb1c>
                                <picture data-v-7cfdcb1c>

                                    <img src="../assets/images/4b2da9.png" width="734" height="580" alt="An upscale porcelain dishware catalog" loading="lazy" class="absolute w-auto h-auto" style="max-height:115%;max-width:100%;top:50%;left:50%;transform:translate(-50%,-50%)">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-lg" data-v-7cfdcb1c>
                    Paper Thickness:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Paper thickness is measured in weight. Lighter weights are thinner and, heavier
                            weights are thicker. The thicker papers are more opaque (less transparent).
                            However, thicker papers cost more. Thinner papers may be preferred if your
                            catalogue needs to make a certain mailing weight. At Bryton Printing, we can
                            calculate the weight of a catalog before it is produced. Standard weights of
                            gloss text are 100lb. 80lb. and 70lb. Standard weights of satin text are also
                            100lb. 80lb. and 70lb. Finally, standard weights for uncoated text are 80lb.
                            70lb. 60lb. and 50lb. Even though the weights of uncoated papers are lighter,
                            The actual thickness is similar to gloss. For example 70lb. uncoated is close to
                            the thickness of 100lb. gloss book. 50lb. uncoated text is similar to 80lb.
                            gloss text.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Cover papers are generally twice as thick as text papers. For example 100lb.
                            gloss cover is twice as thick as 100lb. gloss text. The same is true for satin
                            and uncoated papers. Here is a list of paper weights for gloss and satin cover:
                            130lb. cover; 120lb. cover; 111lb. cover; 100lb. cover; 80lb. cover; 78lb.
                            cover; 65lb. cover. Here is a list of weights for uncoated cover: 110lb. cover;
                            100lb. cover; 80lb. cover; and, 65lb. gloss cover.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Let Bryton Printing assist you in finding the best paper for your catalog or
                            booklet project. We can help steer your project to the best paper for the least.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-base" data-v-7cfdcb1c>
                    Coating: Gloss and Dull:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            In older fashion printing presses, coating is used to add a layer of protection
                            so, the ink does not rub off the paper or scratch. However, with Bryton
                            Printing's modern UV (Ultra Violet) curing technology, ink is about 2.5 times
                            more durable and does not need coating. Printing without coating saves energy
                            and saves money.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            However, some customers prefer the look of coating. Generally, gloss coating is
                            used on gloss paper and dull coating is used on satin paper. However, customers
                            can run gloss paper with dull varnish for a special satin look.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-lg" data-v-7cfdcb1c>
                    Binding Options:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            The final step is bindery. We offer three binding methods: Saddle-stitched (like
                            a magazine); Perfect-bound (like a paperback book); and, Plastic coil (Perfect
                            for calendars and cookbooks).
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Saddle-stitched is the most popular form of binding. When a booklet is
                            saddle-stitched, it will open and lay flat well. This is a good type of bindery
                            if you have pictures that cross over from one page to the next. The highest
                            amount of pages that can be in a saddle-stitched booklet is about 92. The
                            maximum amount of pages is partially determined by the thickness of the paper.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            If you need more than 92 pages, a perfect-bound catalog might be your choice.
                            The actual minimum amount of pages for a perfect-bound catalog is about 32
                            pages. The maximum amount of pages for a perfect bound booklet is about 256.
                            For a more durable catalogue, request PUR glue. PUR glue does not melt and fall
                            apart in a hot car. It is much stronger than regular glue. There are a couple of
                            downsides to perfect binding. One is the cost. It is a little more expensive
                            than saddle-stitched bindery. Also, it is hard to get a perfect bound booklet to
                            lay flat.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            The final type of bindery we offer is plastic-0coil. Plastic coil
                            allows for as many or as few pages as you might like. The big advantage of
                            plastic-coil is that it will lay flat when you open it. This makes it ideal for
                            instruction manuals, cookbooks and calendars.
                        </p>
                    </div>
                </div>
                <h3 class="accented-header w-max-content px-4 my-6 desktop:my-0 col-span-2 row-span-1 desktop:w-full h-8 uppercase text-center font-black text-white items-center justify-center flex text-base" data-v-7cfdcb1c>
                    Finishing Touches:
                </h3>
                <div class="text-center desktop:text-left col-span-5 w-full desktop:border-b last:border-b-0 border-black pb-2" data-v-7cfdcb1c>
                    <div class="nuxt-content" data-v-7cfdcb1c>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            After your order is complete, there are some finishing touches to consider. In
                            some cases, you may want your booklets or catalogs shrink-wrapped. We can shrink
                            wrap in any quantity that you desire. Shrink-wrapping adds an extra measure of
                            protection to your catalogs. It can also assist in the distribution of catalogs
                            especially if the same quantity of booklets needs to go to many locations.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Additionally, we can add standard 3-hole drilling. This works well if your
                            booklet needs to be added to a 3-hole binder. We can also do 5-hole automotive
                            drilling. Automotive drilling allows your catalog to be put into a special
                            automotive binder.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            When we are done, your order will be boxed and labeled beautifully. Don't
                            hesitate to give us special boxing instructions if you need them. Do you want to
                            limit the weight of each box? No problem. We also put special quantities in each
                            box. It is standard for us to put descriptive labels on each box. We can also
                            add custom labels as needed.
                        </p>
                        <p style="text-align:left" data-v-7cfdcb1c>
                            Delivery is available - even out of the state. Delivery of up to one skid of
                            booklets to Los Angeles starts at just $75.
                        </p>
                    </div>
                </div>
            </div>
            <a href="tel:+1-714-993-4800" class="callout uppercase cursor-pointer text-2xl tablet:text-3xl font-black font-black uppercase mx-auto flex" style="--text-color:#000;--reactive-text-color:#000" data-v-8e38860e data-v-7cfdcb1c>
                <div class="animated-underline" style="--underline-color:var(--bryton-yellow-500);--default-underline-width:33%;--tablet-underline-width:33%;--desktop-underline-width:33%;--default-underline-alignment:center;--tablet-underline-alignment:center;--desktop-underline-alignment:center;--default-alignment:center;--tablet-alignment:center;--desktop-alignment:center" data-v-52d451c5 data-v-8e38860e>
                    CALL NOW!
                </div>
            </a>
        </section>
    </main>

</template>
<script>
</script>

<style>
.text-color-p p{
   text-align: left !important;
}
</style>