<template>
    <div class="easter-egg__channel hidden desktop:block" style="width: calc(var(--container-padding-x) + 193px - 75px)">
        <div class="easter-egg">
            small details matter to us

        </div>
    </div>
    <body>

        <main class="flex flex-col justify-around items-center text-center page-container page-container--padded pb-8 desktop:pb-16 flex-grow" data-v-7b216df5>
            <!---->
            <div class="relative w-full tablet:w-9/12 mb-4 tablet:mb-0" data-v-7b216df5>
                <div class="aspect-16-9 relative" data-v-7b216df5>
                    <img src="../assets/images/8a3e01.jpg" width="911" height="573" alt="A scene illustrated in a 'paper-cutout' style showing deer and birds in a pristine meadow" class="object-cover absolute w-full h-full">
                </div>
            </div>
            <div style="width:110%" data-v-7b216df5>
                <h1 class="text-2xl tablet:text-4xl desktop:text-6xl font-black leading-tight mb-8" data-v-7b216df5>
                    If you're concerned about what your printing is doing to the environment, then you've come to the right place.
                </h1>
                <h2 class="text-lg tablet:text-2xl desktop:text-3xl font-extrabold mx-6 mb-12 tablet:mb-16 leading-tight" data-v-7b216df5>
                    We're here to make sure that your job - no matter how simple or complicated - is delivered with little or no negative impact on the environment
                </h2>
                <div class="alternate-list" data-v-7b216df5>
                    <ul class="tablet:text-xl desktop:text-2xl" style="--list-separator-color:black" data-v-7b216df5>
                        <li class="mx-auto" style="width:50%" data-v-7b216df5>
                            All our waste paper is recycled
                        </li>
                        <li class="mx-auto" style="width:62.5%" data-v-7b216df5>
                            All of our aluminum plates are recycled
                        </li>
                        <li class="mx-auto" style="width:75%" data-v-7b216df5>
                            All our ink is UV cured. This ink is better for the environment, because it does not emit solvents with VOC's – (Volatile Organic Compounds)
                        </li>
                        <li class="mx-auto" style="width:87.5%" data-v-7b216df5>
                            Our use of computer aided color control cuts our waist up to 50% and improves our quality at the same time
                        </li>
                        <li class="mx-auto" style="width:100%" data-v-7b216df5>
                            Our solvents (press washes) have 90% less VOC's (Volatile Organic Compounds) than standard press washes
                        </li>
                    </ul>
                </div>
            </div>
        </main>
    </body>
</template>