    <template>
        <section
          class="slider-container"
          :style="{
            '--color': 'var(--bryton-magenta-500)',
            '--accent-color': 'var(--bryton-yellow-500)',
            '--angle': '9deg',
          }"
        >
          <!-- Display the current item -->
          <div class="content">
            <h3>{{ currentData.title }}</h3>
            <p>{{ currentData.description }}</p>
          </div>
      
          <!-- Render dots for navigation -->
          <div class="dots-container">
            <span
              v-for="(item, index) in data"
              :key="index"
              :class="['dot', { active: currentIndex === index }]"
              @click="currentIndex = index"
            ></span>
          </div>
        </section>
      </template>
      
      <script>
      export default {
        data() {
          return {
            currentIndex: 0, // Track which item is currently shown
            data: [
              // Data for 5 Do's
              { title: "Do 1", description: "Description for Do 1" },
              { title: "Do 2", description: "Description for Do 2" },
              { title: "Do 3", description: "Description for Do 3" },
              { title: "Do 4", description: "Description for Do 4" },
              { title: "Do 5", description: "Description for Do 5" },
              // Data for 5 Don'ts
              { title: "Don't 1", description: "Description for Don't 1" },
              { title: "Don't 2", description: "Description for Don't 2" },
              { title: "Don't 3", description: "Description for Don't 3" },
              { title: "Don't 4", description: "Description for Don't 4" },
              { title: "Don't 5", description: "Description for Don't 5" },
            ],
          };
        },
        computed: {
          currentData() {
            return this.data[this.currentIndex];
          },
        },
      };
      </script>
      
      <style scoped>
      .slider-container {
        padding: 20px;
        border: 1px solid var(--accent-color);
        position: relative;
        background: var(--color);
        border-radius: 12px;
        text-align: center;
      }
      
      .content {
        margin-bottom: 20px;
      }
      
      .dots-container {
        display: flex;
        justify-content: center;
        gap: 10px;
      }
      
      .dot {
        width: 10px;
        height: 10px;
        background-color: var(--accent-color);
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
      }
      
      .dot.active {
        transform: scale(1.5);
        background-color: var(--color);
      }
      </style>
      
